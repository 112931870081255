import imageCompression from "browser-image-compression";
import { storage } from "../server/firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { useEffect, useState } from "react";
import { convertTimestampForSort } from "./utility_login";
import axios from "axios";
import { updateDataAdmin } from "./utility_user";

async function compressImage(OldFile, limit, size) {
  const imageFile = OldFile;
  // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  //   console.log(imageFile);
  //   console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: limit,
    maxWidthOrHeight: size,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    //   console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    const file = new File([compressedFile], compressedFile.name, {
      type: compressedFile.type,
      lastModified: new Date().getTime(), // You can also use the lastModified from the original Blob if available
    });

    // const imageUrl = URL.createObjectURL(compressedFile);
    // console.log(file);
    return file;
    //   await uploadToServer(compressedFile); // write your own logic
  } catch (error) {
    console.log(error);
    return false;
  }
}
async function compressImageFromUrl(imageUrl, limit, size) {
  try {
    // Fetch the image as a Blob from the URL
    const response = await fetch(imageUrl);
    if (!response.ok) throw new Error("Network response was not ok");

    const imageBlob = await response.blob();

    // Now compress the Blob
    const options = {
      maxSizeMB: limit,
      maxWidthOrHeight: size,
      useWebWorker: true,
    };
    // const imageCompression = require("browser-image-compression"); // Ensure this is correctly imported
    const compressedFile = await imageCompression(imageBlob, options);
    // console.log(compressedFile);
    // Convert the compressed blob to a File
    const file = new File([compressedFile], "compressed_image", {
      type: compressedFile.type,
      lastModified: new Date().getTime(),
    });

    return file;
  } catch (error) {
    console.error("Error in compressing image from URL:", error);
    return false;
  }
}
async function generateVideos(
  videoObj,
  qualityList = ["1280x720", "640x360"],
  //   quality = ["1280x720", "896x502", "640x360"],
  name = "complete",
  setCurrentUploading,
  setTotalProgress
) {
  let urlList = [];
  const ffmpeg = new FFmpeg();
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
  //   const ffmpeg = ffmpegRef.current;
  //   ffmpeg.on("log", ({ message }) => {
  //     console.log(message);
  //   });
  // let count =0
  ffmpeg.on("progress", (props) => {
    if (setCurrentUploading) {
      setTotalProgress(props.progress * 100);
    }
    //   messageRef.current.innerHTML = `${progress * 100} % (transcoded time: ${
    //     time / 1000000
    //   } s)`;
  });
  // toBlobURL is used to bypass CORS issue, urls with the same
  // domain can be used directly.
  try {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });

    await ffmpeg.writeFile("input.mp4", await fetchFile(videoObj));

    // const qualities = ["640x360"];
    const qualities = qualityList;
    // console.log(qualities);
    const promises = qualities?.map((quality) => {
      return new Promise(async (resolve, reject) => {
        const [width, height] = quality.split("x");
        const outputFilename = `${name}${
          height === "502" ? "480" : height
        }p.mp4`;
        // Run ffmpeg command to resize the video to 720x480
        try {
          setCurrentUploading(
            `Generating: lower resolutions of ${outputFilename}`
          );

          await ffmpeg.exec([
            "-i",
            "input.mp4",
            "-vf",
            `scale=${width}:${height}`,
            outputFilename,
          ]);

          // Read the result
          const data = await ffmpeg.readFile(outputFilename);

          const outputFile = new File([data.buffer], outputFilename, {
            type: "video/mp4",
          });
          //   console.log(outputFile);
          //   // Create URL for the output video
          //   const url = URL.createObjectURL(
          //     new Blob([data.buffer], { type: "video/mp4" })
          //   );
          urlList.push(outputFile);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    });
    await Promise.all(promises);
  } catch (error) {
    console.error("General Error in generateVideos:", error);
  }
  return urlList;
}
async function generateVideosFromURL(
  videoURL,
  qualityList = ["1280x720", "640x360"],
  name = "preview"
) {
  const response = await fetch(videoURL);
  if (!response.ok) throw new Error("Network response was not ok");
  const imageBlob = await response.blob();
  const imageFile = new File([imageBlob], "input.mp4", {
    type: imageBlob.type,
  });

  let urlList = [];
  const ffmpeg = new FFmpeg();
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
  ffmpeg.on("progress", (props) => {});
  try {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });

    await ffmpeg.writeFile("input.mp4", await fetchFile(imageFile));
    const qualities = qualityList;
    const promises = qualities?.map((quality) => {
      return new Promise(async (resolve, reject) => {
        const [width, height] = quality.split("x");
        const outputFilename = `${name}${
          height === "502" ? "480" : height
        }p.mp4`;
        // Run ffmpeg command to resize the video to 720x480
        try {
          await ffmpeg.exec([
            "-i",
            "input.mp4",
            "-vf",
            `scale=${width}:${height}`,
            outputFilename,
          ]);

          // Read the result
          const data = await ffmpeg.readFile(outputFilename);

          const outputFile = new File([data.buffer], outputFilename, {
            type: "video/mp4",
          });
          urlList.push(outputFile);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    });
    await Promise.all(promises);
  } catch (error) {
    console.error("General Error in generateVideos:", error);
  }
  console.log(urlList);
  return urlList;
}
const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src); // Clean up
      resolve(videoElement.duration);
    };

    videoElement.onerror = () => {
      reject("Error loading video.");
    };

    videoElement.src = URL.createObjectURL(file);
  });
};

const getVideoDurationUrl = (mediaUrl) => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration);
    };

    videoElement.onerror = (err) => {
      console.error("Error loading video:", err);
      console.error("Error Type:", err.type);
      console.error("Error Event:", err);

      reject(
        "Error loading video. Possible causes could be CORS issues, invalid URL, network issues, or unsupported format."
      );
    };

    // Set the source directly to the media URL
    videoElement.src = mediaUrl;
  });
};

const deleteFolderContents = (folderRef) => {
  return listAll(folderRef).then((res) => {
    // Collect all promises for file deletions
    const fileDeletionPromises = res.items.map((itemRef) => {
      return deleteObject(itemRef);
    });

    // If there are "subfolders", recurse into them
    const folderDeletionPromises = res.prefixes.map((subfolderRef) => {
      return deleteFolderContents(subfolderRef);
    });

    // Wait for all deletions to complete: files and subfolders
    return Promise.all([...fileDeletionPromises, ...folderDeletionPromises]);
  });
};

async function verifyVideoURL(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) return [false, response];
    const contentType = response.headers.get("Content-Type");

    if (!contentType || !contentType.startsWith("video/")) {
      return [false, response];
      //   throw new Error("URL does not point to a video file.");
    }

    return [true, response]; // URL is valid and points to a video
  } catch (error) {
    console.error("Video URL verification failed:", error);
    return [false, false]; // URL is invalid or not accessible
  }
}

const checkFileSize = async (url, e) => {
  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentLength = response.headers.get("Content-Length");
    return contentLength && parseInt(contentLength) < 1024;
  } catch (error) {
    console.log(e.video_name);
    console.error("Error fetching file size:", error);
    return false;
  }
};

async function getVideoList() {
  try {
    let count = 0;
    const result = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getInfo?type=videos`
    );
    const currentList = await Promise.all(
      result.data?.map(async (e) => {
        let videoDuration = e.video_length;
        count++;

        // IF do not have thumbnail , add one
        // if (video_tn === "" || getFileType(video_tn) !== "image") {
        //   const video_tnObj = await compressImageFromUrl(
        //     e.video_cover,
        //     0.5,
        //     1280
        //   );
        //   const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //   const currentLoginID = e.video_cover.split("%2F")[1];
        //   const newId = e.video_cover.split("%2F")[2];
        //   const storageRefVideoTN = ref(
        //     storage,
        //     `/${userFolder}/${currentLoginID}/${newId}/thumbnail.jpeg`
        //   );
        //   const metadata = {
        //     contentType: "image/jpeg",
        //     uploader: e.video_owner,
        //   };
        //   const uploadTask = uploadBytesResumable(
        //     storageRefVideoTN,
        //     video_tnObj,
        //     metadata
        //   );
        //   uploadTask.on(
        //     "state_changed",
        //     (snapshot) => {
        //       const percent = Math.round(
        //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //       );
        //     },
        //     (err) => alert(err),
        //     () => {
        //       // download url
        //       getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
        //         console.log("count:", count, " ", url);
        //         try {
        //           video_tn = url;
        //           const docRef = doc(db, "accounts", e.video_owner);
        //           const docSnap = await getDoc(docRef);
        //           let user = docSnap.data();
        //           let currentVideoList = user.videos;
        //           const newVideoList = currentVideoList.map((video) => {
        //             if (video.video_id === e.video_id) {
        //               return { ...video, video_tn: url }; // Replace with new form data
        //             } else {
        //               return video; // Keep the existing video data
        //             }
        //           });
        //           const newInfo = {
        //             videos: newVideoList,
        //           };
        //           await updateDoc(docRef, newInfo);
        //         } catch (err) {
        //           console.error(
        //             `Error getting duration for video ${e.video_id}:`,
        //             err
        //           );
        //         }
        //       });
        //     }
        //   );
        // }
        // IF do not have thumbnail , add one
        // const isSmall = await checkFileSize(e.video_preview_tn, e);
        // const isSmall =
        //   new Date(convertTimestampForSort(e.video_release_date)) <
        //   new Date("2024-06-30");
        // const isSmall = false;
        // if (
        //   e.video_preview_tn === "" ||
        //   (!e.video_preview_tn && e.video_preview !== "")
        // ) {
        //   const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //   const currentLoginID = e.video_cover.split("%2F")[1];
        //   const newId = e.video_cover.split("%2F")[2];
        //   console.log("generating preview: ", e.video_name);
        //   const playListObj = await generateVideosFromURL(
        //     e.video_preview,
        //     ["854x480"],
        //     "preview_tn"
        //   );
        //   const storageRefVideoNewPlayList = ref(
        //     storage,
        //     `/${userFolder}/${currentLoginID}/${newId}/preview_tn.mp4`
        //   );
        //   const url = await uploadToFB(
        //     playListObj[0],
        //     "video/mp4",
        //     e.video_owner,
        //     storageRefVideoNewPlayList,
        //     false
        //   );
        //   const VideoMapping = {
        //     video_preview_tn: url,
        //   };
        //   const videoReff = doc(db, "videos", e.video_id);
        //   console.log(VideoMapping);
        //   await updateDoc(videoReff, VideoMapping);
        // }
        // if (
        //   !videoDuration ||
        //   e.video_hide !==
        //     new Date(convertTimestampForSort(e.video_release_date)) > new Date()
        //   // ||
        //   // video_tn === "" ||
        //   // getFileType(video_tn) !== "image"
        // ) {
        //   console.log("1");
        //   let video_hide = e.video_release
        //     ? new Date(convertTimestampForSort(e.video_release_date)) >
        //       new Date()
        //     : e.video_hide;

        //   const VideoMapping = {
        //     video_length: videoDuration,
        //     video_hide: video_hide,
        //     video_tn: video_tn,
        //   };
        //   const videoReff = doc(db, "videos", e.video_id);
        //   await updateDoc(videoReff, VideoMapping);
        // }
        // if (e.video_id === "3aa006c6-32dc-4e8c-b830-9bc705953856") {
        //   console.log(e.video_release);
        //   console.log(e.video_hide);
        // }

        // IF do not have m3u8 , add one

        // if (
        //   !video_srcAuto &&
        //   e.video_src !== "" &&
        //   e.video_src &&
        //   e.video_id === "111"
        // ) {
        //   // console.log(e.video_src)
        //   //   const res = await verifyVideoURL(e.video_src);

        //   const response = await fetch(e.video_src);
        //   if (!response.ok) throw new Error("Network response was not ok");
        //   const contentType = response.headers.get("Content-Type");

        //   if (!contentType || !contentType.startsWith("video/")) {
        //     console.error("URL does not point to a video file.");
        //     // throw new Error("URL does not point to a video file.");
        //   } else {
        //     // console.log(await response.blob());

        //     const videoBlob = await response.blob();
        //     // Create a File object from the Blob
        //     const lastModified = new Date().getTime(); // Use current time as last modified time, or any other logic
        //     const file = new File([videoBlob], "complete.mp4", {
        //       type: videoBlob.type, // Use the MIME type from the blob, or you can define it manually if needed
        //       lastModified: lastModified,
        //     });
        //     const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //     const currentLoginID = e.video_cover.split("%2F")[1];
        //     const newId = e.video_id;

        //     const fileSize = bytesToGB(file.szie);
        //     if (fileSize < 2) {
        //       const playListObj = await generateVideosM3U8(
        //         file,
        //         ["1280x720", "640x360"],
        //         "complete",
        //         false,
        //         false,
        //         userFolder,
        //         currentLoginID,
        //         newId,
        //         e.video_owner
        //       );
        //       const name = "completeAuto.m3u8";

        //       const storageRefVideoNewPlayList = ref(
        //         storage,
        //         `/${userFolder}/${currentLoginID}/${newId}/${name}`
        //       );
        //       const url = await uploadToFB(
        //         playListObj,
        //         "application/vnd.apple.mpegurl",
        //         e.video_owner,
        //         storageRefVideoNewPlayList,
        //         true
        //       );

        //       const VideoMapping = {
        //         video_srcAuto: url,
        //       };
        //       const videoReff = doc(db, "videos", e.video_id);
        //       await updateDoc(videoReff, VideoMapping);
        //     }
        //   }
        // }
        return {
          ...e,
          id: e.video_id,
          label: e.video_name,
          value: e.video_id,
          video_id: e.video_id,
          video_hide: e.video_release
            ? new Date(e.video_release_date) > new Date()
            : e.video_hide,
          video_tn: e.video_tn || e.video_cover,
          video_length: videoDuration,
          // ...(video_srcAuto !== "" && { video_srcAuto: video_srcAuto }),
          // ...(video_src !== "" && { video_src: video_src }),
        };
      })
    );

    // console.log(currentList);
    return currentList;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}

async function getSeriesList(showHide = true) {
  try {
    const result = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getInfo?type=series`
    );
    return result.data;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}
async function getModelList(showHide = false) {
  try {
    const result = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getInfo?type=models`
    );
    // console.log(result);
    return result.data;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}

function formatTimeFromSeconds(totalSeconds) {
  // Check if total seconds are less than 60
  //   if (totalSeconds < 60) {
  //     return `${Math.floor(totalSeconds)}`; // Just return seconds with 's' suffix if less than a minute
  //   }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Build time string
  const hoursStr = hours > 0 ? `${hours}:` : "";
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

  // Concatenate time parts, skipping hours if it's 0
  return `${hoursStr}${minutesStr}:${secondsStr}`;
}
function getFileType(url) {
  if (!url) {
    return "unknown";
  }

  // Attempt to extract the file extension before any query parameters or hashes
  const baseName = url.split("?")[0].split("#")[0];
  const extension = baseName
    .substring(baseName.lastIndexOf(".") + 1)
    .toLowerCase();

  const imageExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "svg"];
  const videoExtensions = ["mp4", "mov", "wmv", "flv", "avi", "webm", "mkv"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown"; // Extension is not recognized
  }
}

function modifyM3U8FileAddToken(file, tsFiles) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // console.log(file);
    // console.log(tsFiles);
    reader.onload = () => {
      const text = reader.result;
      const lines = text.split("\n");

      const modifiedLines = lines.map((line) => {
        if (line.trim().endsWith(".ts")) {
          //   console.log(line);
          const suffix = tsFiles?.find((e) => e.includes(line))?.split("?")[1];
          //   console.log(suffix);
          const preffix = tsFiles
            .find((e) => e.includes(line))
            .split("/o/")[1]
            .split(line)[0];
          //   console.log(preffix);
          return `${preffix}${line}?${suffix}`;
        }
        return line;
      });
      const modifiedText = modifiedLines.join("\n");
      const modifiedFile = new File([modifiedText], file.name, {
        type: "application/vnd.apple.mpegurl",
      });
      resolve(modifiedFile);
    };

    reader.onerror = () => reject(reader.error);

    reader.readAsText(file);
  });
}
function getVideoResolution(videoFile) {
  return new Promise((resolve, reject) => {
    // Create an off-screen video element
    const video = document.createElement("video");
    video.preload = "metadata"; // Load only metadata to speed up the process

    video.onloadedmetadata = function () {
      resolve(`${this.videoWidth}x${this.videoHeight}`);
      URL.revokeObjectURL(video.src); // Clean up the created object URL
    };

    video.onerror = function () {
      reject("Unable to load video file.");
      URL.revokeObjectURL(video.src); // Ensure to revoke object URL on error
    };

    video.src = URL.createObjectURL(videoFile); // Set the source of the video to the file
  });
}

async function generateVideosM3U8(
  videoObj,
  qualityList = ["1280x720", "640x360"],
  //   quality = ["1280x720", "896x502", "640x360"],
  name = "complete",
  setCurrentUploading,
  setTotalProgress,
  folderPath,
  userID,
  videoID,
  currentLoginAccount
) {
  console.log(videoID);
  console.log(videoObj);
  const fileSize = bytesToGB(videoObj.size);

  if (fileSize < 2) {
    let urlList = [];
    const ffmpeg = new FFmpeg();
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const masterPlaylistContent = ["#EXTM3U", "#EXT-X-VERSION:3"];
    const ctype = "application/vnd.apple.mpegurl";
    const originalQuality = await getVideoResolution(videoObj);
    qualityList.push(originalQuality);
    qualityList.reverse();
    //   const preffix= `${folderPath}%2F${userID}%2F${videoID}%2FvideosTs%2F`
    ffmpeg.on("progress", (props) => {
      if (setTotalProgress) {
        setTotalProgress(props.progress * 100);
        if (props.progress * 100 === 100) {
          setCurrentUploading((prev) =>
            prev.replace("Generating", "Generation Completed: ")
          );
        }
      }
    });
    // ffmpeg.on("log", ({ message }) => {
    //   console.log(message);
    // });

    try {
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
      });

      await ffmpeg.writeFile("input.mp4", await fetchFile(videoObj));

      try {
        // Generate variant playlists
        for (const [index, quality] of qualityList.entries()) {
          const [width, height] = quality.split("x");
          const outputFilename = `complete${height}p.m3u8`;
          if (setCurrentUploading) {
            setCurrentUploading(`Generating ${height}p resolution`);
          }
          console.log("Gening: ", videoObj?.name, height);
          await ffmpeg.exec([
            "-i",
            "input.mp4",
            //   "-codec: copy",
            "-profile:v",
            "baseline",
            "-level",
            "3.0",
            "-s",
            `${width}x${height}`,
            "-start_number",
            "0",
            "-hls_time",
            "10",
            "-hls_list_size",
            "0",
            "-f",
            "hls",
            outputFilename,
          ]);
          const data = await ffmpeg.readFile(outputFilename);
          const outputFile = new File([data.buffer], outputFilename, {
            type: ctype,
          });
          // Now, fetch all related .ts files
          const tsFiles = [];
          const fileList = await ffmpeg.listDir("/");
          // console.log(fileList);

          const fileOperations = fileList.map(async (file) => {
            if (file.name.endsWith(".ts")) {
              const tsData = await ffmpeg.readFile(file.name);
              const tsFile = new File([tsData.buffer], file.name, {
                type: "video/MP2T",
              });
              const storageRefVideoNew = ref(
                storage,
                `/${folderPath}/${userID}/${videoID}/videosTs/${
                  outputFilename.split(".")[0]
                }/${file.name}`
              );
              // Assuming uploadToFB returns a promise that resolves to a URL
              const url = uploadToFB(
                tsFile,
                "video/MP2T",
                currentLoginAccount,
                storageRefVideoNew,
                false
              ); // Push the URL once upload is done
              return url;
            }
          });

          await Promise.all(fileOperations);
          const urls = (await Promise.all(fileOperations)).filter(
            (url) => url != null
          );
          // console.log(urls);
          const newM3U8Obj = await modifyM3U8FileAddToken(outputFile, urls);
          const storageRefVideoNew = ref(
            storage,
            `/${folderPath}/${userID}/${videoID}/videosTs/${outputFilename}`
          );
          const url = await uploadToFB(
            newM3U8Obj,
            ctype,
            currentLoginAccount,
            storageRefVideoNew,
            true
          );
          const suffix = url?.split("?")[1];
          const preffix = url?.split("/o/")[1].split("complete")[0];
          // Add variant to master playlist
          masterPlaylistContent.push(
            `#EXT-X-STREAM-INF:BANDWIDTH=${Math.floor(
              (1000000 * (parseInt(width) + parseInt(height))) / 360
            )},RESOLUTION=${width}x${height}`
          );
          masterPlaylistContent.push(`${preffix}${outputFilename}?${suffix}`);
        }

        // Create master playlist file
        const masterPlaylistBlob = new Blob(
          [masterPlaylistContent.join("\n")],
          {
            type: "application/vnd.apple.mpegurl",
          }
        );
        const masterPlaylistFile = new File(
          [masterPlaylistBlob],
          "masterPlaylist.m3u8",
          { type: "application/vnd.apple.mpegurl" }
        );

        // const masterPlaylistUrl = URL.createObjectURL(masterPlaylistBlob);
        //   console.log(urlList);
        return masterPlaylistFile;
      } catch (error) {
        console.error("Error generating HLS:", error);
        return null;
      }
    } catch (error) {
      console.error("General Error in generateVideos:", error);
      return null;
    }
  } else {
    console.log("bigger than 2GB!");
    return false;
  }
}

function replaceURLWithPort(url) {
  // const searchPattern = "https://firebasestorage.googleapis.com";
  const searchPattern =
    "firebasestorage.googleapis.com/v0/b/spring-firm-studios-iii.appspot.com";
  const searchPattern2 =
    "storage.googleapis.com/spring-firm-studios-iii.appspot.com";

  const replaceWith = "1942441321.rsc.cdn77.org";
  const replaceWith2 = "1982370245.rsc.cdn77.org";

  let newURL = url;
  // let newURL = url.replace(searchPattern, replaceWith);
  // newURL = newURL.replace(searchPattern2, replaceWith2);
  // const replaceWith = "https://firebasestorage.googleapis.com:443";
  return newURL;
}

function bytesToGB(bytes, decimals = 2) {
  const factor = 1024 * 1024 * 1024; // Number of bytes in a gigabyte
  const gigabytes = bytes / factor;
  return gigabytes.toFixed(decimals);
}

function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function arraysAreEqual(arr1, arr2) {
  // Check if the arrays are of different lengths
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Check if all elements are the same
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all elements are the same
  return true;
}
async function uploadToFB(
  fileobj,
  ctype,
  currentLoginAccount,
  storageRef,
  isM3u8
) {
  return new Promise((resolve, reject) => {
    let metadata = {
      contentType: ctype,
      uploader: currentLoginAccount,
    };

    if (isM3u8) {
      metadata.contentDisposition = `attachment; filename="${fileobj.name}"`;
    }

    const uploadTask = uploadBytesResumable(storageRef, fileobj, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // console.log(percent + "% done"); // You can update a progress state or something similar here.
      },
      (err) => {
        console.error("Error during upload: ", err);
        reject(err); // Reject the promise on error
      },
      () => {
        // Handle successful upload
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url); // Resolve the promise with the URL
        });
      }
    );
  });
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}

export {
  useWindowSize,
  arraysAreEqual,
  toTitleCase,
  bytesToGB,
  getVideoDuration,
  compressImage,
  getVideoList,
  formatTimeFromSeconds,
  getFileType,
  generateVideos,
  generateVideosM3U8,
  replaceURLWithPort,
  uploadToFB,
  getVideoResolution,
  getModelList,
  getSeriesList,
  getVideoDurationUrl,
};
