import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import isMobileDevice from "../../context/getUserClient";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "../../context/index";
import DropMenu from "./dropMenu";
import CryptoJS from "crypto-js";
import {
  topUp,
  newAccount,
  login,
  permissionSign,
  checkActive,
} from "../../context/utility_login";
import NavbarM from "./navbar-M";
import { getVideoList, useWindowSize } from "../../context/utility";
import { getAuth, onAuthStateChanged } from "firebase/auth";
let mobile = isMobileDevice();

export default function Navbar() {
  const { state, dispatch } = useContext(Context);
  const {
    language,
    currentLoginAccount,
    currentLoginID,
    currentLoginPermission,
    account,
  } = state;
  const [isLogin, setIsLogin] = useState(false);
  const [profile, setIsProfile] = useState(false);
  const location = useLocation();
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (currentLoginID !== "") {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [currentLoginID]);

  // function handleChange(lang) {
  //   //false = EN  true = CN \
  //   window.location.reload();
  //   if (lang) {
  //     dispatch({
  //       type: "language",
  //       payload: lang,
  //     });
  //     // language = lang;
  //   } else {
  //     dispatch({
  //       type: "language",
  //       payload: "EN",
  //     });
  //     // language = 'EN';
  //   }
  //   localStorage.setItem("lang", JSON.stringify(language));
  // }

  useEffect(() => {
    // Function to call when location changes
    const handleRouteChange = () => {
      // setLogin(false);
      dispatch({
        type: "isOpen",
        payload: false,
      });
    };

    // Call the function to ensure login is set to false on route change
    handleRouteChange();

    // Optionally, clean up the effect when the component unmounts or location changes
    return () => {
      handleRouteChange();
    };
  }, [location]);

  useEffect(() => {
    async function updateData() {
      const queryParams = new URLSearchParams(window.location.search);
      let token = queryParams.get("token");
      if (token) {
        // console.log(`token=${token}`);
        // 对token进行URL解码
        token = token.replace(/\s/g, "+");
        const decodedToken = decodeURIComponent(token);

        const bytes = CryptoJS.AES.decrypt(decodedToken, "XPRING");
        const originalToken = bytes.toString(CryptoJS.enc.Utf8);

        const regInfoList = originalToken.split("#");
        console.log(regInfoList);

        if (regInfoList.length >= 4) {
          const regInfo = {
            email: regInfoList[0],
            password: regInfoList[1],
            newId: regInfoList[2],
            day: regInfoList[3],
          };

          // console.log(localLoginID)
          const localLoginID = JSON.parse(localStorage.getItem("loginID"));
          const updatedAccount = localLoginID
            ? await topUp(regInfo, account)
            : await newAccount(regInfo);

          // const permissionString = permissionSign(
          //   updatedAccount.permission_groups,
          //   updatedAccount.expiry_date
          // );
          // dispatch({
          //   type: "account",
          //   payload: updatedAccount,
          // });
          // dispatch({
          //   type: "currentLoginAccount",
          //   payload: updatedAccount.username,
          // });
          // dispatch({
          //   type: "currentLoginID",
          //   payload: updatedAccount.id,
          // });
          // dispatch({
          //   type: "currentLoginPermission",
          //   payload: permissionString,
          // });
          window.location.href = "/";
        } else {
          window.location.href = "/";
        }
      }
    }
    updateData();
  }, []);

  //Render Interface Starts Here
  // useEffect(() => {
  //   const scale = width / designWidth;
  //   if (ref.current) {
  //     ref.current.style.transform = `scale(${scale})`;
  //     ref.current.style.transformOrigin = "top left";
  //     ref.current.style.width = `${designWidth}px`;
  //     ref.current.style.height = `${(0.1 * height) / scale}px`;
  //   }
  // }, [width, height]);

  return (
    <div
      className="navbarWrapper"
      style={{
        // position: "sticky",
        // top: "0px",
        // left: 0,
        width: "100%",
        height: "63px",
        background: "rgba(0, 0, 0, 0.5)",
        // background: "linear-gradient(to bottom, #000, rgba(0, 0, 0, 0))",
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
        // padding: "0% 5%",
        zIndex: 999,
      }}
    >
      {width <= 800 ? (
        <NavbarM isLogin={isLogin} setIsLogin={setIsLogin} />
      ) : (
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            width: "100%",
            maxWidth: "1280px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <Link to="/" className="no-hover">
              <div className="cls-13New">
                <span style={{ color: "rgb(105, 35, 35)" }}>X</span>
                <span className="cls-14New" style={{ color: "#FFF" }}>
                  PRING
                </span>
              </div>
            </Link>
            {/* <img
                id="LOGONVB"
                src={process.env.PUBLIC_URL + logoPath}
                alt="img"
              /> */}
            {/* </Link> */}
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "0px 5%",
              justifyContent: "left",
              alignItems: "center",
              gap: "5%",
            }}
          >
            <div>
              <Link
                to={"/video-list"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="video_bn">
                  <div id="VIDEOS">
                    <span>Scenes</span>
                  </div>
                </div>
              </Link>
            </div>

            <Link
              to={"/model-list"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>Stars</span>
                </div>
              </div>
            </Link>
            <Link
              to={"/series-list"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>Series</span>
                </div>
              </div>
            </Link>
          </div>

          {isLogin ? (
            <div
              id="Login_group"
              // style={{ background: "#fff" }}
              // onMouseLeave={() => {
              //   setIsProfile(false);
              //   // dispatch({
              //   //   type: "isOpenMenu",
              //   //   payload: false,
              //   // });
              // }}
            >
              {(currentLoginPermission === "free" ||
                currentLoginPermission === "guest") && (
                <div className="JOIN_NOW_BN">
                  <Link
                    to="/payment"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "100%",
                    }}
                  >
                    <span>{language === "EN" ? "JOIN NOW" : "加入会员"}</span>
                  </Link>
                </div>
              )}
              <div
                // onMouseEnter={() => {
                //   setIsProfile(true);
                // }}
                onClick={() => setIsProfile(true)}
                className="user_btn"
              >
                {/* <img
                  src="./ui/user_outline.png"
                  style={{ height: "20px", width: "auto" }}
                /> */}
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <defs>
                    <mask
                      id="mask"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <image
                        xlinkHref="./ui/user_outline.png"
                        width="20"
                        height="20"
                      />
                    </mask>
                  </defs>
                  <rect
                    width="20"
                    height="20"
                    fill="#f2f2f2"
                    mask="url(#mask)"
                  />
                </svg>
                <div> {currentLoginAccount} </div>
              </div>

              {profile && (
                <DropMenu setIsProfile={setIsProfile} setIsLogin={setIsLogin} />
              )}
            </div>
          ) : (
            <div id="Login_group">
              <div
                className="LOGIN_BN"
                onClick={() => {
                  // setLogin(true);
                  dispatch({
                    type: "isOpen",
                    payload: true,
                  });
                }}
              >
                <span>{language === "EN" ? "LOGIN" : "登陆"}</span>
              </div>
              <div className="JOIN_NOW_BN">
                <Link
                  to="/register"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  <span>{language === "EN" ? "JOIN NOW" : "加入会员"}</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
