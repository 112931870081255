import "../../pages/Login/login.css";
import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  login,
  sendCustomPasswordResetEmail,
} from "../../context/utility_login";
import { Context } from "../../context/index";
import { Checkbox, Button, message } from "antd";
import {
  MailOutlined,
  KeyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import CryptoJS from "crypto-js";
import { getVideoList } from "../../context/utility";

export default function LoginInterface({
  onClose,
  setIsLogin,
  setForget,
  forget,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const { state, dispatch } = useContext(Context);
  const { language, currentPurchased } = state;
  const [loading, setLoading] = useState(false);

  const [emailLogin, setEmailLogin] = useState(
    localStorage.getItem("user-email") || ""
  );
  const [passwordLogin, setPasswordLogin] = useState("");
  const currentXpring = useRef(0);

  // click Sign in button
  async function loginAccount() {
    //check email address
    // console.log(emailLogin, passwordLogin);
    messageApi.loading({
      content: "Processing",
      duration: 35,
      key: "login",
    });

    if (emailLogin === "") {
      messageApi.error({
        content:
          language === "EN"
            ? "Please enter your email address!"
            : "请输入您的邮箱。",
        duration: 5,
        key: "login",
      });
      return;
    }

    setLoading(true);
    const result = await login(emailLogin, passwordLogin);
    if (result[0] === false) {
      setLoading(false);
      messageApi.error({
        content: language === "EN" ? result[1] : result[2],
        duration: 5,
        key: "login",
      });
    } else {
      setLoading(false);

      dispatch({
        type: "account",
        payload: result[1],
      });
      dispatch({
        type: "currentLoginAccount",
        payload: result[1].username,
      });
      dispatch({
        type: "currentLoginID",
        payload: result[1].id,
      });
      dispatch({
        type: "currentLoginPermission",
        payload: result[2],
      });
      dispatch({
        type: "currentPurchased",
        payload: currentPurchased,
      });
      const Vresult = await getVideoList();
      // console.log(Vresult);
      dispatch({
        type: "videoList",
        payload: Vresult,
      });
      currentXpring.current = result[1].beans;
      messageApi.success({
        content: "Login Success",
        duration: 5,
        key: "login",
      });
      if (setIsLogin) {
        setIsLogin(true);
        onClose();
      }
    }
  }

  //Forget Password
  const forgetPassword = async () => {
    if (emailLogin === "") {
      messageApi.error({
        content:
          language === "EN"
            ? "Please enter your email address!"
            : "请输入您的邮箱。",
        duration: 5,
        key: "error",
      });
      return;
    }
    let user = {};
    const encryptedToken = CryptoJS.AES.encrypt(user.id, "XPRING").toString();
    const encodedToken = encodeURIComponent(encryptedToken);
    // console.log(encodedToken);
    setForget(true);
    try {
      sendCustomPasswordResetEmail(emailLogin);
      // await axios.post(
      //   // `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/sendVerificationEmail`,
      //   `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/sendVerificationEmail?email=${emailLogin}&code=${encodedToken}`
      //   // {
      //   //   emailLogin,
      //   //   code: verificationCode,
      //   // },
      //   // {
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //   },
      //   // }
      // );
    } catch (error) {
      console.error("Error sending email:", error);
      console.log("Error sending email:", error);
    }
  };

  return (
    <>
      {contextHolder}

      {!forget ? (
        <>
          <div>
            <span className="cls-X">X</span>
            <span className="cls-14" y="0">
              PRING
            </span>
          </div>
          <div className="cls-6">Member Sign in</div>
          <div className="cls-9">
            <h1
              style={{
                color: "rgb(255, 255, 255)",
              }}
            >
              Access your Xpring ID
            </h1>
          </div>
          <div style={{ padding: "5% 10%" }}>
            <div className="input-gp">
              <span className="input-icon">
                <MailOutlined />
              </span>
              <input
                className="input"
                placeholder="Enter your email address"
                type="text"
                onChange={(e) => setEmailLogin(e.target.value)}
              />
            </div>
            <div className="input-gp">
              <span className="input-icon">
                <KeyOutlined />
              </span>

              <input
                className="input"
                placeholder="Enter a password"
                type="password"
                onChange={(e) => setPasswordLogin(e.target.value)}
              />
            </div>
            <div style={{ textAlign: "left" }}>
              <Checkbox
                style={{ color: "#FFF" }}
                onChange={(e) => {
                  localStorage.setItem("user-email", emailLogin);
                  // console.log(e.target.checked);
                }}
              >
                Remember me on this computer
              </Checkbox>
            </div>
          </div>
          <Button className="signIn-btn" onClick={loginAccount}>
            {" "}
            {language === "EN" ? "Sign in" : "登录"}
          </Button>
          <div style={{ marginTop: "20px" }} className="cls-1">
            Don’t have an account yet?{" "}
            <Link to={"/register"} onClick={onClose ? onClose : null}>
              <span y="0" className="cls-J">
                JOIN NOW
              </span>
            </Link>
            <div
              className="cls-5"
              onClick={forgetPassword}
              style={{ cursor: "pointer" }}
            >
              Forgot Password
            </div>
          </div>
        </>
      ) : (
        <div>
          <CheckCircleOutlined style={{ fontSize: "50px", color: "#97393c" }} />
          <div style={{ margin: "20px 0px 15px 0px" }}>You’ve got email!</div>
          <div style={{ display: "inline-block" }}>
            Please check your inbox, you’ll find an email to reset your password
          </div>
        </div>
      )}
    </>
  );
}
