import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import React, { useState, useEffect, useRef, useContext } from "react";
// import "../../App.css";
import { Context } from "../../context/index";
import { RiShieldCheckLine, RiVideoUploadFill } from "react-icons/ri";
import {
  LogoutOutlined,
  VideoCameraOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { logout } from "../../context/utility_login";

export default function DropMenu({ setIsProfile, setIsLogin, mobile }) {
  const { state, dispatch } = useContext(Context);
  const {
    language,
    currentLoginAccount,
    currentLoginID,
    currentLoginPermission,
  } = state;
  const ref = useRef(null);

  function logOut() {
    setIsLogin(false);
    setIsProfile(false);
    logout();
    localStorage.clear();
    localStorage.setItem("confirmOver18", true);
    dispatch({
      type: "RESET_STATE",
    });
    dispatch({
      type: "over18",
      payload: true,
    });

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }

  useEffect(() => {
    // Function to call when click is detected outside
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsProfile(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsProfile]);

  return (
    <div
      style={
        mobile
          ? { position: "relative", top: 0 }
          : {
              position: "absolute",
              top: "-9px",
              right: "-15px",
              background: "#fff",
              zIndex: 999,
            }
      }
      ref={ref}
      // onMouseLeave={
      //   () => setIsProfile(false)
      // }
    >
      <div className="user_menu">
        <Link
          to={"/myAccount"}
          className="user_menu_item"
          onClick={() => setIsProfile(false)}
        >
          <div id="Security_profile">
            {" "}
            <span className="menu-X">X</span>My Account
          </div>
        </Link>

        <Link
          onClick={() => setIsProfile(false)}
          className="user_menu_item"
          to={"/myVideo"}
        >
          <VideoCameraOutlined />
          <div id="Security_profile">My Videos</div>
        </Link>

        {(currentLoginPermission === "admin" ||
          currentLoginPermission === "official") && (
          <>
            <Link
              className="user_menu_item"
              to={"/upload"}
              onClick={() => setIsProfile(false)}
            >
              <RiVideoUploadFill />
              <div id="Security_profile">Upload</div>
            </Link>
            <Link
              className="user_menu_item"
              to={"/analysis"}
              onClick={() => setIsProfile(false)}
            >
              <DatabaseOutlined />
              <div id="Security_profile">Analysis</div>
            </Link>
          </>
        )}

        <div className="user_menu_item" onClick={() => logOut()}>
          <LogoutOutlined />
          <div>Log Out</div>
        </div>
      </div>
    </div>
  );
}
