import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "./RoutePath";
// import LoginNew from "./pages/Authentication/NewAuthentication/login";

export default function CustomRoute(props) {
  //   console.log(props.isAuthenticated);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {Path.map((element, i) => {
          //   console.log(element);

          return (
            <Route
              key={i}
              exact
              path={element.path}
              element={<element.component />}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}
