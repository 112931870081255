import { doc, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";
import { db, storage, auth } from "../server/firebase";
import axios from "axios";
import { createLog } from "./utility_login";
import { getSeriesList, getVideoList } from "./utility";

const deleteFolderContents = (folderRef) => {
  return listAll(folderRef).then((res) => {
    // Collect all promises for file deletions
    const fileDeletionPromises = res.items.map((itemRef) => {
      return deleteObject(itemRef);
    });

    // If there are "subfolders", recurse into them
    const folderDeletionPromises = res.prefixes.map((subfolderRef) => {
      return deleteFolderContents(subfolderRef);
    });

    // Wait for all deletions to complete: files and subfolders
    return Promise.all([...fileDeletionPromises, ...folderDeletionPromises]);
  });
};

async function AddHistory(id, account) {
  try {
    const userData = account;
    createLog(userData, "watch_video", id);
    let currentHistoryList = userData?.history || [];
    if (!currentHistoryList?.find((e) => e === id)) {
      currentHistoryList.push(id);
      const newInfo = {
        id: account.id,
        history: currentHistoryList,
      };
      try {
        const response = await updateAccount(newInfo);
        // console.log("testing:", response.data);
      } catch (error) {
        console.error("Error testing", error);
      }
    }
    // console.log(currentHistoryList);
    return currentHistoryList;
  } catch (error) {
    if (account.id === "") {
      console.error(`Failed to like this video as you haven't login`);
      return false;
    } else {
      console.error(`Failed to like this video:${error}`);
      return false;
    }
  }
}

async function SaveVideo(id, account) {
  try {
    const UserData = account;
    createLog(UserData, "save_video", id);

    let currentSavedList = UserData.saved || [];
    // Check if the id already exists in the saved list
    if (!currentSavedList.includes(id)) {
      currentSavedList.push(id);
    } else {
      console.log(`Video ID ${id} is already in the saved list.`);
    }
    const newInfo = {
      id: account.id,
      saved: currentSavedList,
    };
    try {
      const response = await updateAccount(newInfo);
    } catch (error) {
      console.error("Error testing", error);
    }

    return currentSavedList;
  } catch (error) {
    if (account.id === "") {
      console.error(`Failed to like this video as you haven't login`);
      return false;
    } else {
      console.error(`Failed to like this video:${error}`);
      return false;
    }
  }
}
async function RemoveVideo(id, account, type) {
  try {
    const UserSnapData = account;
    let newInfo = {};
    let afterDelete = [];
    if (type === "Saved") {
      afterDelete = UserSnapData.saved.filter((e) => e !== id);
      newInfo = { id: account.id, saved: afterDelete };
    } else {
      afterDelete = UserSnapData.history.filter((e) => e !== id);
      newInfo = { id: account.id, history: afterDelete };
    }
    try {
      const response = await updateAccount(newInfo);
    } catch (error) {
      console.error("Error testing", error);
    }
    return afterDelete;
  } catch (err) {
    console.error(err);
    return false;
  }
}

async function starred(id, account) {
  try {
    const idToken = localStorage.getItem("authToken");
    const videoPreData = await getVideoList();
    const videoData = videoPreData?.find((e) => e.video_id === id);
    const UserData = account;
    try {
      const response = await axios.post(
        `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateVideo`,
        {
          video_id: id,
          video_starred: videoData?.video_starred + 1,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
          },
        }
      );
    } catch (error) {
      console.error("Error testing", error);
    }
    createLog(UserData, "like_video", id);

    let currentSavedList = UserData.starred;

    // Check if the id already exists in the saved list
    if (!currentSavedList.includes(id)) {
      currentSavedList.push(id);
    } else {
      console.log(`Video ID ${id} is already in the saved list.`);
    }

    const newInfo = {
      id: account.id,
      starred: currentSavedList,
    };

    try {
      const response = await axios.post(
        `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateAccount`,
        newInfo,
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
          },
        }
      );
    } catch (error) {
      console.error("Error testing", error);
    }
    // console.log(currentSavedList);
    return currentSavedList;
  } catch (error) {
    if (account.id === "") {
      console.error(`Failed to like this video as you haven't login`);
      return false;
    } else {
      console.error(`Failed to like this video:${error}`);
      return false;
    }
  }
  return;
}

async function unstarred(id, account) {
  try {
    const idToken = localStorage.getItem("authToken");
    const videoPreData = await getVideoList();
    const videoData = videoPreData?.find((e) => e.video_id === id);
    const UserData = account;
    try {
      // await update;
      const response = await axios.post(
        `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateVideo`,
        {
          video_id: id,
          video_starred: videoData.video_starred - 1,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
          },
        }
      );
    } catch (error) {
      console.error("Error testing", error);
    }
    let newInfo = {};
    let afterDelete = [];
    afterDelete = UserData.starred.filter((e) => e !== id);
    newInfo = { id: account.id, starred: afterDelete };
    try {
      const response = await axios.post(
        `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateAccount`,
        newInfo
      );
    } catch (error) {
      console.error("Error testing", error);
    }
    return afterDelete;
  } catch (err) {
    console.error(err);
    return false;
  }
}

async function DeleteVideo(
  id,
  account,
  ListType = "Uploaded",
  admin = false,
  path
) {
  try {
    const videoPreData = await getVideoList();
    const videoData = videoPreData?.find((e) => e.video_id === id);
    const UserData = account;
    let afterDelete = [];
    let newInfo = {};
    afterDelete = UserData.videos.filter((e) => {
      if (e.video_id !== id) {
        return e;
      }
    });

    if (videoData.video_series && videoData.video_series !== "") {
      const seriesList = await getSeriesList();
      const seriesL = seriesList.filter(
        (e) => e.seris_name === videoData.video_series
      );
      const seriesToUpdate = seriesL[0];
      const old_seriesVideo_List = seriesToUpdate.series_videos;
      const new_seriesVideo_Lsit = old_seriesVideo_List.filter((e) => e !== id);
      updateDataAdmin(
        { id: seriesToUpdate.id, series_videos: new_seriesVideo_Lsit },
        "series"
      );
    }
    // console.log(afterDelete);
    newInfo = {
      saved: UserData.saved.filter((e) => e !== id),
      history: UserData.history.filter((e) => e !== id),
      videos: UserData.videos.filter((e) => e.video_id !== id),
    };
    await updateAccount(newInfo);

    // if (admin && path) {
    //   const paths = path?.split("%2F");
    //   //   const storageRef = ref(storage, `${path[0]/path[1]/path[2]`);
    //   const folderRef = ref(
    //     storage,
    //     paths[0]?.split("/o/")[1] + "/" + paths[1] + "/" + paths[2]
    //   );

    //   deleteFolderContents(folderRef)
    //     .then(() => {
    //       console.log("All files and subfolders have been deleted");
    //     })
    //     .catch((error) => {
    //       console.error("Error deleting files:", error);
    //     });

    //   deleteDoc(docRef)
    //     .then(() => {
    //       console.log("Document successfully deleted!");
    //     })
    //     .catch((error) => {
    //       console.error("Error removing document: ", error);
    //     });
    // }

    //alert(`successfully deleted ${id}`);
    return afterDelete;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}

async function DeleteModel(id, type, path) {
  try {
    const docRef = doc(db, type === "actor" ? "models" : "series", id);
    const paths = path?.split("%2F");
    //   const storageRef = ref(storage, `${path[0]/path[1]/path[2]`);
    const folderRef = ref(
      storage,
      paths[0]?.split("/o/")[1] + "/" + paths[1] + "/" + paths[2]
    );

    deleteFolderContents(folderRef)
      .then(() => {
        console.log("All files and subfolders have been deleted");
      })
      .catch((error) => {
        console.error("Error deleting files:", error);
      });

    deleteDoc(docRef)
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
    //alert(`successfully deleted ${id}`);
    return true;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}

async function HideModel(id, type) {
  try {
    await updateDataAdmin(
      type === "actor"
        ? { id: id, model_hide: true }
        : { id: id, series_hide: true },
      type
    );
    //alert(`successfully deleted ${id}`);
    return true;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}
async function UnHideModel(id, type) {
  try {
    await updateDataAdmin(
      type === "actor"
        ? { id: id, model_hide: false }
        : { id: id, series_hide: false },
      type
    );
    //alert(`successfully deleted ${id}`);
    return true;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}

async function UnhideVideo(
  id,
  account,
  ListType = "Uploaded",
  admin = false,
  path
) {
  try {
    const UserData = account;
    let afterDelete = [];
    let newInfo = {};

    afterDelete = UserData.videos.map((e) => {
      if (e.video_id === id) {
        return { ...e, vidoe_hide: false };
      } else {
        return e;
      }
    });
    newInfo = { videos: afterDelete };

    await updateDataAdmin({ id: id, video_hide: false }, "video");
    await updateAccount(newInfo);

    //alert(`successfully deleted ${id}`);
    return afterDelete;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}
async function HideVideo(
  id,
  account,
  ListType = "Uploaded",
  admin = false,
  path
) {
  try {
    const UserData = account;
    let afterDelete = [];
    let newInfo = {};

    afterDelete = UserData.videos.map((e) => {
      if (e.video_id === id) {
        return { ...e, vidoe_hide: true };
      } else {
        return e;
      }
    });
    newInfo = { videos: afterDelete };
    await updateDataAdmin({ id: id, video_hide: true }, "video");
    await updateAccount(newInfo);

    //alert(`successfully deleted ${id}`);
    return afterDelete;
  } catch (err) {
    // setError([1, `Failed to delete file ${id}:${err}`]);
    console.error(err);
    return false;
  }
}

async function getUserData(currentLoginID, Token) {
  try {
    const idToken = Token || localStorage.getItem("authToken");
    const response = await axios.get(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getUserData?userId=${currentLoginID}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
        },
        // params: {
        //   getUserData: currentLoginID, // Passing the userId as a query parameter
        // },
      }
    );
    const userData = await response.data;
    // console.log("User data:", userData);

    return userData;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}

async function updateAccount(newAccountInfo) {
  try {
    const idToken = localStorage.getItem("authToken");
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateAccount`,
      newAccountInfo,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error testing", error);
  }
}

async function updateDataAdmin(newAccountInfo, type) {
  try {
    const idToken = localStorage.getItem("authToken");
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/updateDataAdmin?type=${type}`,
      newAccountInfo,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error testing", error);
  }
}

async function createDataAdmin(newAccountInfo, type) {
  try {
    const idToken = localStorage.getItem("authToken");
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/createDataAdmin?type=${type}`,
      newAccountInfo,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error testing", error);
  }
}

async function sendFeedback(regInfo, type) {
  try {
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/sendFeedback?type=${type}`,
      regInfo
    );
    return "success";
  } catch (error) {
    console.log("error");
    return "error";
  }
}

export {
  createDataAdmin,
  updateDataAdmin,
  updateAccount,
  getUserData,
  AddHistory,
  SaveVideo,
  RemoveVideo,
  starred,
  unstarred,
  DeleteModel,
  UnHideModel,
  HideModel,
  UnhideVideo,
  HideVideo,
  DeleteVideo,
  sendFeedback,
};
