import "./footer.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { Context } from "../../context";
import isMobileDevice from "../../context/getUserClient";

export default function CSBottom() {
  const { state, dispatch } = useContext(Context);
  const { language } = state;
  return (
    <div id="btm-container">
      <div style={{ width: "100%", maxWidth: "1280px", margin: "0 auto" }}>
        <div className="footerText"></div>
        <div>
          <Row
            justify="space-between"
            style={{ height: "auto" }}
            className="FooterContent"
          >
            <Col span={6}>
              <div>
                <div id="Xpring_title">
                  <span>
                    <h1>{language === "EN" ? "Xpring" : "关于"}</h1>
                  </span>
                </div>
                <div className="footerItem">
                  <Link
                    to="/"
                    onClick={function (e) {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                      });
                    }}
                  >
                    <h1>{language === "EN" ? "Home" : "首页"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=8`}>
                    <h1>{language === "EN" ? "About us" : "关于我们"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to="https://twitter.com/XpringFilmLDN" target="_blank">
                    <h1>{language === "EN" ? "Twitter" : "推特"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link
                    to="https://www.instagram.com/springstudiosldn/"
                    target="_blank"
                  >
                    <h1>{language === "EN" ? "Instagram" : "INSTA"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to="https://springfilmstudios.com/" target="_blank">
                    <h1>
                      {language === "EN" ? "Spring Film Studios" : "SpringFilm"}
                    </h1>
                  </Link>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <div id="Xpring_title">
                  <span>
                    <h1>{language === "EN" ? "Help" : "帮助"}</h1>
                  </span>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=4`}>
                    <h1>{language === "EN" ? "FAQ" : "常见问题"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/contact?type=0`}>
                    <h1>{language === "EN" ? "Contact us" : "联系我们"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/contact?type=1`}>
                    <h1>{language === "EN" ? "Improve Xpring" : "改进意见"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`http://cs.segpay.com/`}>
                    <h1>
                      {language === "EN" ? "Billing support" : "付费支持"}
                    </h1>
                  </Link>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <div id="Xpring_title">
                  <span>
                    <h1>{language === "EN" ? "Legal" : "法律法规"}</h1>
                  </span>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=0`}>
                    <h1>{language === "EN" ? "Terms of use" : "使用协议"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=1`}>
                    <h1>{language === "EN" ? "Privacy policy" : "隐私政策"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=2`}>
                    <h1>
                      {language === "EN" ? "Cookies policy" : "Cookies政策"}
                    </h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=3`}>
                    <h1>{language === "EN" ? "Copyright" : "版权"}</h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=7`}>
                    <h1>
                      {language === "EN" ? "2257 Statement" : "2257 声明"}
                    </h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=9`}>
                    <h1>
                      {language === "EN" ? "Complain Policy" : "投诉政策"}
                    </h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=10`}>
                    <h1>
                      {language === "EN"
                        ? "Content Management Policy"
                        : "内容管理政策"}
                    </h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=11`}>
                    <h1>
                      {language === "EN" ? "Chargeback Policy" : "退单政策"}
                    </h1>
                  </Link>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <div id="Xpring_title">
                  <span>
                    <h1>{language === "EN" ? "Monetization" : "商务合作"}</h1>
                  </span>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=5`}>
                    <h1>
                      {language === "EN" ? "Model Recruitment" : "模特招募"}
                    </h1>
                  </Link>
                </div>
                <div className="footerItem">
                  <Link to={`/msg?content=6`}>
                    <h1>
                      {language === "EN" ? "Advertisement Plans" : "广告位招租"}
                    </h1>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          {/* <svg className="divider" viewBox="0 0 1920 1">
          <path id="divider" d="M 0 0 L 1920 0" />
        </svg> */}
          <div
            style={{
              textAlign: "center",
              width: "100%",
              fontSize: "12px",
              // wordWrap: "break-word",
              marginTop: "50px",
              whiteSpace: "normal",
              color: "#838383",
            }}
          >
            Subscriptions recur until canceled. If you need more subscription
            information or for any other billing inquiries. Please visit SegPay,
            our authorized sales agent 18 U.S.C. 2257 Record-Keeping
            Requirements Compliance Statement. All persons depicted herein were
            at least 18 years of age at the time of the photography. In
            Compliance of 18 U.S.C. Section 2257. All images, content &
            intellectual materials are copyright © 2024 Xpring. All rights
            Reserved.18 U.S.C. 2257 and 2257A Record-Keeping Requirements
            Compliance Statement.
          </div>
        </div>

        <div className="RTA">
          <img src={`${process.env.PUBLIC_URL}/ui/main/RTA.png`} />
          <h1>Copyright © 2024 Xpring.co.uk, All rights reserved.</h1>
        </div>
      </div>
    </div>
  );
}
