import React, { useEffect, useState, useContext } from "react";
import { Layout } from "antd";
import "./publicLayout.css";
import CSHeader from "../Header/header";
import CSBottom from "../Footer/bottom";
import { useLocation } from "react-router-dom";
import { Context } from "../../context/index";
import Login from "../Modal/login";
import LineChart from "../Graph/LineChart";

export default function PublicLayout({ children }) {
  const { Content } = Layout;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [re, setRe] = useState(false);
  const [dolly, setDolly] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { isOpen, isOpenMenu } = state;

  // Check if the current URL starts with "/play"
  const isPlayPage = location.pathname.endsWith("/");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  // Log the current URL
  useEffect(() => {
    const currentUrl = window.location.origin.split("//")[1];
    if (currentUrl === "springfilmstudios.com") {
      setRe(true);
    }
    if (location.pathname.endsWith("/dolly")) {
      setDolly(true);
    } else {
      setDolly(false);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
    };

    const preventScroll = (e) => {
      e.stopPropagation();
    };

    if (open) {
      // Add event listeners to prevent scrolling
      document.addEventListener("wheel", preventDefault, { passive: false });
      document.addEventListener("scroll", preventScroll, true);
      document.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
    } else {
      // Remove event listeners to restore scrolling
      document.removeEventListener("wheel", preventDefault);
      document.removeEventListener("scroll", preventScroll, true);
      document.removeEventListener("touchmove", preventDefault);
    }

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("wheel", preventDefault);
      document.removeEventListener("scroll", preventScroll, true);
      document.removeEventListener("touchmove", preventDefault);
    };
  }, [open]);

  return (
    <>
      <Layout className="Layout">
        {re ? (
          <>
            <div className="container">
              <div className="message">Please go to our new site</div>
              <button
                className="button"
                onClick={() => {
                  window.location.href = "https://xpring.co.uk/";
                }}
              >
                Go to xpring.co.uk
              </button>
            </div>
          </>
        ) : dolly ? (
          <>
            {/* <CSHeader /> */}
            <Content
              style={{
                margin: "0 auto",
                height: "auto",
                minHeight: "100%",
                background: "#000",
                width: "100%",
                background: "#000",
                // paddingBottom: "100px",
                // zIndex: 1,
              }}
            >
              {children}
            </Content>
            <CSBottom />{" "}
          </>
        ) : (
          <>
            <CSHeader />
            <Content
              style={{
                margin: "0 auto",
                height: "auto",
                minHeight: "100%",
                background: "#000",
                maxWidth: isPlayPage ? "100%" : "1280px",
                width: "100%",
                background: "#000",
                // paddingBottom: "100px",
                // zIndex: 1,
              }}
            >
              {children}
            </Content>
            <CSBottom style={{ zIndex: 9999 }} />{" "}
          </>
        )}
      </Layout>
      {open && (
        <Login
          onClose={() => {
            setOpen(false);
            dispatch({
              type: "isOpen",
              payload: false,
            });
          }}
          setIsLogin={setOpen}
        />
      )}
    </>
  );
}
