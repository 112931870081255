import "../../pages/Login/login.css";
import React, { useState } from "react";
import LoginInterface from "./loginInterface";

function Login({ onClose, setIsLogin }) {
  const [forget, setForget] = useState(false);

  return (
    <div className="login-wrapper">
      {/* {contextHolder} */}
      <div className="filter" onClick={() => onClose()} />
      <div className="login-modal" style={{ width: forget ? "auto" : "500px" }}>
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "25px",
            fontSize: "18px",
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          X
        </div>
        <LoginInterface
          setIsLogin={setIsLogin}
          onClose={onClose}
          setForget={setForget}
          forget={forget}
        />
      </div>
    </div>
  );
}

export default Login;
