import "./App.css";
import PublicLayout from "./component/layout/publicLayout";
import { BrowserRouter as Router } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "./context/index";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import CustomRoute from "./Route";
import { getVideoList, getModelList } from "./context/utility";
import "plyr/dist/plyr.css";
import { checkActive, permissionSign } from "./context/utility_login";
import { getUserData } from "./context/utility_user";

export async function checkPermission(id, dispatch, authToken) {
  const localLoginName = JSON.parse(localStorage.getItem("loginName"));
  const idToken = localStorage.getItem("authToken");
  const cSSData = await getUserData(id, idToken);
  const resultA = await checkActive(cSSData.email);

  dispatch({
    type: "account",
    payload: {
      ...cSSData,
      active: resultA.active,
      record: resultA.record,
      active_record: resultA.active_record,
    },
  });
  dispatch({
    type: "currentLoginAccount",
    payload: localLoginName,
  });

  const permissionString = permissionSign(
    cSSData.permission_groups,
    cSSData.expiry_date
  );
  dispatch({
    type: "currentLoginPermission",
    payload: permissionString,
  });
}

export default function App() {
  const { state, dispatch } = useContext(Context);
  const [user, setUser] = useState(null);
  const { currentLoginID } = state;
  const didFetch = useRef(false);

  useEffect(() => {
    if (didFetch.current) return;
    didFetch.current = true;

    const localLoginID = JSON.parse(localStorage.getItem("loginID"));
    const authToken = localStorage.getItem("authToken");

    if (localLoginID !== "" && localLoginID !== null && authToken !== null) {
      autoSignIn(authToken);
      dispatch({
        type: "currentLoginID",
        payload: localLoginID,
      });
      checkPermission(localLoginID, dispatch);
    } else {
      dispatch({
        type: "currentLoginPermission",
        payload: "guest",
      });
    }

    async function fetchData() {
      console.log("fetch");
      const result = await getVideoList();
      dispatch({
        type: "videoList",
        payload: result,
      });
      const models = await getModelList();
      dispatch({
        type: "modelList",
        payload: models,
      });
    }
    fetchData();

    // moveFieldsToSubcollection();

    //     changeDocumentId(
    //   "accounts",
    //   "659d8a4f-ae44-4e75-985a-0f20277ea815",
    //   "1YW8gYe9G4RsZFoyr3scwsWuCAn1"
    // );
  }, []);

  function autoSignIn(token) {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("User is already signed in:", user);
        // You can also refresh the token if needed
        user.getIdToken(true).then((idToken) => {
          localStorage.setItem("authToken", idToken);
        });
      } else {
        if (token) {
          // Optionally, you can use this token to authenticate the user again if needed
          // console.log("User token found in localStorage:", token);
        }
      }
    });
  }

  return (
    <Router>
      <PublicLayout>
        <CustomRoute />
      </PublicLayout>
    </Router>
  );
}
