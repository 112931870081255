// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need

const firebaseConfig = {
  apiKey: "AIzaSyCjs_xmGrg-qs9C6O4GTGh0qibK6WBT6Y4",
  authDomain: "spring-firm-studios-iii.firebaseapp.com",
  projectId: "spring-firm-studios-iii",
  storageBucket: "spring-firm-studios-iii.appspot.com",
  messagingSenderId: "754451346467",
  appId: "1:754451346467:web:7545099664730789b2ed96",
  measurementId: "G-4EFJHYM5LH",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// export const db = {};
// export const storage = {};

// if (process.env.NODE_ENV !== "development") {
//   const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
//     isTokenAutoRefreshEnabled: true, // Automatically refresh App Check tokens
//   });
// }
