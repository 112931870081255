import { auth } from "../server/firebase";
// import uuid from "react-native-uuid";
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import bcrypt from "bcryptjs";
// import CryptoJS from "crypto-js";
import axios from "axios";
import { getUserData, updateAccount } from "./utility_user";

function isJavaScriptDate(obj) {
  return obj instanceof Date;
}

function convertTimestamp(timestamp, symbol = "/") {
  if (
    typeof timestamp == "number" ||
    !timestamp ||
    typeof timestamp == "string"
  ) {
    //alert(`Detected ${typeof(timestamp)}`);
    return "NaN";
  }

  let date = new Date();
  if (isJavaScriptDate(timestamp)) {
    date = new Date(timestamp);
  } else {
    date = timestamp?.toDate();
  }

  let mm = date?.getMonth() + 1;
  let dd = date?.getDate();
  let yyyy = date?.getFullYear();

  date = dd + symbol + mm + symbol + yyyy;

  return date;
}

function firebaseTimestampToDate(firebaseTimestamp) {
  if (!firebaseTimestamp) {
    throw new Error("Invalid Firebase timestamp.");
  }

  // Check for the presence of _seconds or seconds
  const seconds = firebaseTimestamp._seconds || firebaseTimestamp.seconds;
  const nanoseconds =
    firebaseTimestamp._nanoseconds || firebaseTimestamp.nanoseconds;

  if (seconds === undefined || nanoseconds === undefined) {
    if (nanoseconds === undefined) {
      return new Date(seconds * 1000);
    } else {
      return new Date();
    }

    // throw new Error("Invalid Firebase timestamp format.");
  }

  // Convert to milliseconds and create a Date object
  return new Date(seconds * 1000 + nanoseconds / 1000000);
}

function convertTimestampForSort(timestamp, symbol = "-", type = "number") {
  let date = new Date();

  if (
    typeof timestamp == "number" ||
    !timestamp ||
    typeof timestamp == "string"
  ) {
    let mm = date?.getMonth() + 1;
    let dd = date?.getDate();
    let yyyy = date?.getFullYear();

    date = yyyy + symbol + mm + symbol + dd;
    return date;
  }

  if (isJavaScriptDate(timestamp)) {
    date = new Date(timestamp);
  } else {
    date = firebaseTimestampToDate(timestamp);
  }

  let mm = date?.getMonth() + 1;
  let dd = date?.getDate();
  let yyyy = date?.getFullYear();
  if (type === "long") {
    const monthNamesFull = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName} ${yyyy}`;
  } else if (type === "short") {
    const monthNamesFull = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName}, ${yyyy}`;
  } else {
    date = `${yyyy}${symbol}${mm}${symbol}${dd}`;
  }
  return date;
}

function compareWithToday(inputDate) {
  // Parse the input date string "dd/mm/yyyy" to a Date object
  const [day, month, year] = inputDate.split("/");
  const date = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript

  // Get today's date with time set to 00:00:00 for accurate comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Compare the input date with today's date
  if (date > today) {
    return date.toLocaleDateString();
  } else {
    return today.toLocaleDateString();
  }
}

function permissionSign(group, expiry_date = false) {
  if (group.includes("admin")) {
    return "admin";
  }
  if (group.includes("official")) {
    return "official";
  }
  if (group.includes("trial")) {
    if (group.includes("yes")) {
      return "trial-yes";
    } else {
      return "trial-no";
    }
  }
  if (expiry_date) {
    const [day, month, year] = convertTimestampForSort(expiry_date, "-").split(
      "-"
    );
    if (
      group.includes("free") ||
      new Date(`${year}-${month}-${day}`) < new Date()
    ) {
      return "free";
    }
  } else {
    if (group.includes("free")) {
      return "free";
    }
  }

  if (group.includes("member")) {
    return "member";
  }

  return "empty";
}

async function newAccount(regInfo) {
  const days = parseInt(regInfo.day);
  // const newId = regInfo?.newId || uuid.v4();

  try {
    const hashedPassword = await bcrypt.hash(regInfo.password, 10);

    const userCredential = await createUserWithEmailAndPassword(
      auth,
      regInfo.email,
      regInfo.password
    );
    const user = userCredential.user;

    const newAccountInfo = {
      id: user.uid,
      email: regInfo.email,
      username: regInfo.email.split("@")[0],
      password: hashedPassword,
      reg_time: new Date(),
      last_login_time: new Date(),
      permission_groups: ["user", days > 0 ? "member" : "free"],
      status: 1, // 1=normal 2=locked 3=disabled my admin
      starred: [],
      videos: [],
      purchased_videos: [],
      expiry_date: new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000),
      selfie: "./defaultSelfie.png",
      sub: "",
      inbox: [],
      freq_watch: [],
      history: [],
      purchase_history: [],
      beans: 0,
      tel: "",
      plan_used: days,
    };
    try {
      const response = await axios.post(
        `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/registerUser`,
        newAccountInfo
      );
      console.log("testing:", response.data);
    } catch (error) {
      console.error("Error testing", error);
    }
    localStorage.setItem("loginName", JSON.stringify(newAccountInfo.username));
    localStorage.setItem("loginID", JSON.stringify(newAccountInfo.id));
    // createLog(newAccountInfo, "register");
    // window.location.href = "/";
    //sendEmail(email,`Welcome to Xpring`,`Dear ${username}, thanks for joining us!`);
    return newAccountInfo;
  } catch (error) {
    console.log("error");
    return { id: false, error: error };
  }
}

async function createLog(logs, action, video = "", before = "", after = "") {
  const newLoginLogInfo = {
    logs: logs,
    action: action,
    video: video,
    before: before,
    after: after,
  };
  try {
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/createLog`,
      newLoginLogInfo
    );
    // console.log("testing:", response.data);
  } catch (error) {
    console.error("Error createLog", error);
  }
}

async function topUp(regInfo, account) {
  try {
    const id = regInfo.newId;
    const expiryDay = parseInt(regInfo.day);
    const data = account;
    const Ldate = compareWithToday(convertTimestamp(data.expiry_date));
    const parts = Ldate.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Adjust month because JavaScript months are 0-based
    const year = parseInt(parts[2], 10);

    const startDate = new Date(year, month, day);
    const renewDate = new Date(
      startDate.getTime() + expiryDay * 24 * 60 * 60 * 1000
    );
    const newAccountInfo = {
      permission_groups: ["user", "member"],
      expiry_date: renewDate,
      plan_used: expiryDay,
    };
    await updateAccount(newAccountInfo);
    createLog(newAccountInfo, "topup");
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
async function checkActive(email) {
  let userL = [];
  try {
    const idToken = localStorage.getItem("authToken");
    const response = await axios.get(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getLogs?type=postbackLogs`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Attach the ID token in the Authorization header
        },
      }
    );
    userL =
      response.data === "No logs found for the provided email."
        ? []
        : response.data;
    console.log(userL);
  } catch (error) {
    console.error("Error checkActive", error);
  }

  let seenActions = new Set();
  let recordP = userL?.filter((e) => e.action === "Auth");
  recordP = recordP.filter((e) => {
    if (!seenActions.has(e.purchaseid)) {
      seenActions.add(e.purchaseid);
      return true;
    }
    return false;
  });
  let recordC = userL?.filter((e) => e.action === "Cancel");
  let recordE = userL?.filter((e) => e.action === "Expired");
  seenActions = new Set();
  recordC = recordC.filter((e) => {
    if (!seenActions.has(e.purchaseid)) {
      seenActions.add(e.purchaseid);
      return true;
    }
    return false;
  });
  seenActions = new Set();
  recordE = recordE.filter((e) => {
    if (!seenActions.has(e.purchaseid)) {
      seenActions.add(e.purchaseid);
      return true;
    }
    return false;
  });
  // console.log(recordE);
  const active =
    recordP
      .map((e) => e.purchaseid)
      .filter(
        (e) =>
          !recordC.map((ele) => ele.purchaseid).includes(e) &&
          !recordE.map((ele) => ele.purchaseid).includes(e)
      ).length > 0
      ? true
      : false;
  return {
    active: active,
    record: userL || [],
    active_record: recordP
      .map((e) => e.purchaseid)
      .filter(
        (e) =>
          !recordC.map((ele) => ele.purchaseid).includes(e) &&
          !recordE.map((ele) => ele.purchaseid).includes(e)
      ),
  };
}

async function login(emailLogin, passwordLogin) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      emailLogin,
      passwordLogin
    );
    const idToken = await userCredential.user.getIdToken();
    localStorage.setItem("authToken", idToken);
    const userID = userCredential.user.uid;
    // console.log("User signed in !!: ", userCredential.user);
    const cSSData = await getUserData(userID);
    if (cSSData.empty) {
      return [
        false,
        "Username or email address does not exist.",
        "您输入的账号不存在。",
      ];
    } else {
      let user = cSSData;
      // const passwordMatch = await bcrypt.compare(passwordLogin, user.password);
      const passwordMatch = true;
      if (!passwordMatch) {
        return [false, "Username or password incorrect.", "账号或密码错误。"];
      } else {
        if (user.permission_groups.includes("ban")) {
          return [
            false,
            "Your account was banned from use for some reason.",
            "您的账号当前被禁止使用。",
          ];
        }
        const permissionString = permissionSign(
          user.permission_groups,
          user.expiry_date
        );
        console.log(permissionString);
        const newLoginInfo = {
          id: user.id,
          last_login_time: new Date(),
          permission_groups: [user.permission_groups[0], permissionString],
          plan_used: permissionString === "free" ? 0 : user.plan_used,
        };

        try {
          await updateAccount(newLoginInfo);
          createLog(user, "login");
          // console.log("testing:", response.data);
        } catch (error) {
          console.error("Error testing", error);
        }
        const activeR = await checkActive(emailLogin);
        console.log(activeR);
        // console.log(activeR);
        // permissionString === "free" &&
        if (permissionString === "free" && activeR.active) {
          for (let i = 0; i < activeR.active_record.length; i++) {
            try {
              const response = await axios.post(
                `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/addPostback`,
                {
                  // id: userID,
                  // username: user.username,
                  email: emailLogin,
                  action: "Expired",
                  date: new Date(),
                  purchaseid: activeR.record[i],
                }
              );
              console.log("testing:", response.data);
            } catch (error) {
              console.error("Error testing", error);
            }
          }
        }

        let userUnread = 0;
        const userInbox = user.inbox;
        userInbox.forEach((element) => {
          if (!element.read) {
            userUnread += 1;
          }
        });
        if (userUnread > 99) {
          localStorage.setItem("unread", JSON.stringify("99+"));
        } else {
          localStorage.setItem("unread", JSON.stringify(userUnread));
        }

        // console.log(idToken);

        localStorage.setItem("loginID", JSON.stringify(userID));
        localStorage.setItem("loginName", JSON.stringify(user.username));
        localStorage.setItem("permission", permissionString);
        return [true, { ...user, newLoginInfo }, permissionString];
      }
    }
  } catch (error) {
    console.log("getting error");
    return [
      false,
      `Something went wrong trying to find the documents: ${error}`,
      `Something went wrong trying to find the documents: ${error}`,
    ];
  }
}

async function logout() {
  try {
    await signOut(auth);
    console.log("user signed out");
  } catch (error) {
    console.error("error signing out:", error);
  }
}

async function sendCustomPasswordResetEmail(email) {
  const auth = getAuth();

  // Define the action code settings
  // const actionCodeSettings = {
  //   url: "https://xpring.co.uk/change-password", // Your custom reset password page URL
  //   handleCodeInApp: true, // Set to true if you want the link to be opened in the app
  // };

  try {
    // Send the password reset email with the custom action code settings
    // await sendPasswordResetEmail(auth, email, actionCodeSettings);
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent successfully.");
    return {
      success: true,
      message: "Password reset email sent. Please check your inbox.",
    };
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return { success: false, message: error.message };
  }
}

export {
  newAccount,
  createLog,
  topUp,
  login,
  logout,
  permissionSign,
  convertTimestamp,
  checkActive,
  convertTimestampForSort,
  sendCustomPasswordResetEmail,
};
