import React from "react";
import { Line } from "@ant-design/charts";
import { convertTimestampForSort } from "../../context/utility_login";

const LineChart = ({ newdata, filter }) => {
  const countOccurrencesByDate = (arr, time) => {
    if (!arr || arr?.length === 0) {
      return [];
    }
    const counts = arr?.reduce((acc, obj) => {
      try {
        const date = new Date(convertTimestampForSort(obj[time]))
          .toISOString()
          .split("T")[0]; // Standardize date format to YYYY-MM-DD
        if (!acc[date]) {
          acc[date] = 1;
        } else {
          acc[date]++;
        }
      } catch (e) {
        console.error("Error processing record:", obj);
        console.error(e);
      }
      return acc;
    }, {});
    //   console.log(counts);

    // Transform the counts object into the desired array format
    const result = Object?.keys(counts)?.map((date) => {
      return {
        date: date,
        value: counts[date],
      };
    });
    result.sort((a, b) => new Date(a.date) - new Date(b.date));
    //   console.log(result);
    //   console.log(JSON.stringify(result, null, 2));
    return result;
  };

  const data =
    filter === "expiry_date"
      ? countOccurrencesByDate(
          newdata?.filter(
            (e) =>
              new Date(convertTimestampForSort(e.expiry_date)) >= new Date()
          ),
          "reg_time"
        )
      : countOccurrencesByDate(newdata, filter);
  const config = {
    data,
    // height: 400,
    xField: "date",
    yField: "value",
    axis: {
      x: {
        titleStroke: "#fff",
        tickStroke: "#fff",
        labelFill: "#fff",
        gridStroke: "#fff",
        lineStroke: "#fff",
      },
      y: {
        titleStroke: "#fff",
        tickStroke: "#fff",
        labelFill: "#fff",
        gridStroke: "#fff",
        lineStroke: "#fff",
      },
    },
  };
  return <Line {...config} />;
};
export default LineChart;
