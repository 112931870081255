import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { Context } from "../../context/index";
// import "./header.css";
// export let confirmOver18V = false;
// export const confirmOver18V = React.createContext();

function Over18Pop() {
  const { state, dispatch } = useContext(Context);
  const { over18 } = state;
  const [confirmOver18Vs, setConfirmOver18Vs] = useState(false);
  const [isKid, setIsKid] = useState(false);
  const [isClicked, setIsClick] = useState(false);

  async function Confirm() {
    localStorage.setItem("confirmOver18", JSON.stringify(true));
    setConfirmOver18Vs(true);
    dispatch({
      type: "over18",
      payload: true,
    });
    // confirmOver18V = true;
  }

  return !isClicked && !over18 ? (
    // return !isClicked && !confirmOver18Vs ? (
    <div className="pop-up-over-18">
      <h1>Please confirm your age</h1>
      <br />
      <button
        className="btnA"
        onClick={function (e) {
          setIsClick(true);
          setIsKid(false);
          Confirm();
        }}
      >
        I'm over 18
      </button>
      <br />
      <br />
      <button
        className="btnD"
        onClick={function (e) {
          setIsClick(true);
          setIsKid(true);
        }}
      >
        I'm under 18
      </button>
      <br />
      <br />
      <p>
        This website contains age-restricted materials including nudity and
        explicit depictions of sexual activity.
      </p>
    </div>
  ) : isKid && !over18 ? (
    <div className="pop-up-over-18">
      <h1>The content of this website is not for under 18</h1>

      <br />
      <button
        className="btnA"
        onClick={function (e) {
          setIsClick(true);
          setIsKid(false);
          Confirm();
        }}
      >
        I'm actually 18
      </button>
      <br />
      <br />
      <Link to="https://www.google.com">
        <button className="btnD">Sorry, I'm out</button>
      </Link>
      <br />
      <br />
      <p>
        This website contains age-restricted materials including nudity and
        explicit depictions of sexual activity.
      </p>
    </div>
  ) : null;
}

export default Over18Pop;
