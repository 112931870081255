import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider as ContextProvider } from "./context/index";
import App from "./App";
import { ConfigProvider } from "antd";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ContextProvider>
      <ConfigProvider
        theme={{
          components: {
            Message: {
              contentBg: "rgba(50,50,50)",
              colorText: "#fff",
              fontSize: 16,
              contentPadding: "10px 30px",
            },

            Input: {
              borderRadius: "2px",
              colorBorder: "#3c3c43",
              activeBorderColor: "#838383",
              activeShadow: "rgba(170,170,170)",
              hoverBorderColor: "#838383",
              // hoverBorderColor: "rgba(100,100,100)",
              colorIcon: "rgba(100,100,100)",
              colorIconHover: "rgba(100,100,100)",
              defaultHoverBorderColor: "rgba(100,100,100)",
            },
            Button: {
              defaultHoverBorderColor: "rgba(100,100,100)",
              defaultHoverColor: "rgba(100,100,100)",
              colorFill: "rgba(100,100,100)",
              colorText: "#fff",
            },
            Select: {
              selectorBg: "rgba(0,0,0,0)",
              colorText: "#fff",
              optionSelectedBg: "#222",
              borderRadius: 1,
              borderRadiusSM: 1,
              colorBgElevated: "rgba(0,0,0)",
            },
            Collapse: {
              borderRadiusLG: 1,
            },
            Steps: {
              colorPrimary: "#97393C",
            },
            Modal: {
              colorBgElevated: "rgba(0,0,0)",
            },
            Slider: {
              railBg: "#606060",
              railHoverBg: "#606060",
              trackBgDisabled: "#606060",
              dotBorderColor: "#606060",
              handleColorDisabled: "#606060",
            },
            Table: {
              colorText: "#f2f2f2",
              colorTextHeading: "#f2f2f2",
              headerColor: "#f2f2f2",
            },
          },
          token: {
            colorBgContainer: "transparent",
            // colorBorder: "rgba(100,100,100)",
            colorText: "rgba(150,150,150)",
            colorTextPlaceholder: "rgba(100,100,100)",
            borderRadius: "10px",
            colorIcon: "rgba(100,100,100)",
            colorIconHover: "rgba(100,100,100)",
            // activeBorderColor: "rgba(100,100,100)",
            hoverBorderColor: "rgba(100,100,100)",
            colorLinkHover: "rgba(100,100,100)",

            // colorBgContainer: "rgba(0,0,0)",
            colorBgElevated: "rgba(0,0,0)",
          },
        }}
      >
        {/* <RouterProvider router={router} /> */}
        <App />
      </ConfigProvider>
    </ContextProvider>
  </React.StrictMode>
);
