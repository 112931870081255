import "../../App.css";
import React, { Fragment, useState, useEffect, useContext } from "react";
import Navbar from "./navbar";
import Over18Pop from "./over18dec";
import { Context } from "../../context/index";

export default function CSHeader() {
  const { state, dispatch } = useContext(Context);
  const localConfirmOver18 = JSON.parse(localStorage.getItem("confirmOver18"));
  const localGetLangSetting = JSON.parse(localStorage.getItem("lang"));

  // console.log("render");

  useEffect(() => {
    if (localGetLangSetting) {
      dispatch({
        type: "language",
        payload: localGetLangSetting,
      });
      // language = localGetLangSetting;
    } else {
      dispatch({
        type: "language",
        payload: "EN",
      });
      // language = "EN";
    }
  }, [localGetLangSetting]);

  useEffect(() => {
    if (localConfirmOver18) {
      // confirmOver18V = true;
      dispatch({
        type: "over18",
        payload: true,
      });
    } else {
      // confirmOver18V = false;
      dispatch({
        type: "over18",
        payload: false,
      });
    }
  }, [localConfirmOver18]);

  return (
    <div style={{ width: "100%", position: "sticky", top: "0px", zIndex: 999 }}>
      <Navbar />
      <Over18Pop />
    </div>
  );
}
