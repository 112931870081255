import React from "react";
import { Navigate } from "react-router-dom"; // Use Navigate for React Router v6, Redirect for v5

export default function RequireAuth({ Component, permissionKey, admin }) {
  // Retrieve the required permission from localStorage

  if (admin) {
    if (
      localStorage.getItem(permissionKey) !== "official" &&
      localStorage.getItem(permissionKey) !== "admin"
    ) {
      return <Navigate to="/error" />;
    }
  }

  if (
    localStorage.getItem(permissionKey) === null ||
    localStorage.getItem(permissionKey) === undefined
  ) {
    return <Navigate to="/login" />;
  }

  return <Component />;
}
